import React from 'react';
import Layout from 'src/components/Layout';

const NotFoundPage = () => {
  return (
    <Layout>
      <h1>Not Found</h1>
      <p>You just hit a page that doesn&#39;t exist...</p>
    </Layout>
  );
};

export default NotFoundPage;
